import {Button, Flex, Input, InputNumber, Modal, Switch, Form, Result, Spin} from "antd";
import {useEffect, useState} from "react";
import {postUserToEdit, useUserToEdit} from "../../hooks/api";


const EditUserModal = ({isModalOpen, setIsModalOpen, email}) => {
    const [responseData, setResponseData] = useState(null);
    const [userToEdit, isUserToEditLoading, userToEditError] = useUserToEdit(email, [isModalOpen, setIsModalOpen, email, responseData]);
    const [defaultValues, setDefaultValues] = useState({
        id: "",
        email: "",
        phone: "",
        activatedLk: false,
        minutesTotal: 0,
        resetMinutes: false
    });
    const [form] = Form.useForm();

    useEffect(() => {
        if (userToEdit) {
            setDefaultValues({
                id: userToEdit.id,
                email: userToEdit.email,
                phone: userToEdit.phone,
                activatedLk: userToEdit.activatedLk,
                minutesTotal: userToEdit.minutesTotal,
                resetMinutes: false
            });
        }
    }, [userToEdit])


    useEffect(() => {
        form.setFieldsValue(defaultValues);
    }, [form, defaultValues]);

    if (!userToEdit) {
        return (
            <Modal title={"Изменить пользователя"} open={isModalOpen}
                   onCancel={() => setIsModalOpen(false)}
                   width={"60vw"}
                   cancelText={"Отмена"}
                   okButtonProps={{
                       hidden: true
                   }}
            >
                {/*<Spin/>*/}
            </Modal>
        );
    } else if (userToEdit && userToEdit.isAnonymous) {
        return (
            <Modal title={"Изменить пользователя"} open={isModalOpen}
                   onCancel={() => setIsModalOpen(false)}
                   width={"60vw"}
                   cancelText={"Отмена"}
                   okButtonProps={{
                       hidden: true
                   }}
            >
                <Result
                    status={"error"}
                    title="Пользователь с таким email не найден"
                />
            </Modal>
        );
    } else if (userToEdit) {
        return (
            <Modal title={"Изменить пользователя: id" + defaultValues.id} open={isModalOpen}
                   onCancel={() => setIsModalOpen(false)}
                   width={"60vw"}
                   cancelText={"Отмена"}
                   okText={"Изменить"}
                   okButtonProps={{
                       autoFocus: true,
                       htmlType: 'submit',
                   }}
                   modalRender={(dom) => (
                       <Form
                           form={form}
                           name="basic"
                           labelCol={{
                               // span: 8,
                           }}
                           wrapperCol={{
                               // span: 16,
                           }}
                           style={{
                               // maxWidth: 600,
                           }}
                           layout={"vertical"}
                           initialValues={defaultValues}
                           onFinish={(e) => {
                               postUserToEdit(email, e, setResponseData);
                           }}
                           onFinishFailed={(e) => console.log(e)}
                           autoComplete="off"
                       >
                           {dom}
                       </Form>
                   )}
            >
                <Form.Item
                    label="ID"
                    name="id"
                >
                    <Input disabled/>
                </Form.Item>

                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста, введите email',
                            type: 'email',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Телефон"
                    name="phone"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста, введите телефон',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Активировать ЛК"
                    name="activatedLk"
                    valuePropName="checked"
                >
                    <Switch/>
                </Form.Item>

                <Form.Item
                    label="Всего минут"
                    name="minutesTotal"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста, введите количество минут',
                        },
                    ]}
                >
                    <InputNumber min={0}/>
                </Form.Item>

                <Form.Item
                    label={"Сбросить минуты"}
                    name="resetMinutes"
                    valuePropName="checked"
                >
                    <Switch/>
                </Form.Item>
            </Modal>
        );
    }

}

const EditUser = () => {
    const [userEmail, setUserEmail] = useState("");
    const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);


    return (
        <Flex gap={"large"}>
            <Input placeholder="john@example.org"
                   style={{width: "80vw", maxWidth: 400}}
                   allowClear onChange={(e) => setUserEmail(e.target.value)}/>
            <Button type={"primary"} onClick={() => setIsEditUserModalOpen(true)}>Изменить</Button>

            <EditUserModal isModalOpen={isEditUserModalOpen} setIsModalOpen={setIsEditUserModalOpen} email={userEmail}/>
        </Flex>
    );

}

export default EditUser;