import {Space, Input, Button, Typography, Flex, Alert, Divider, Form} from "antd";
import {useContext, useEffect, useState} from "react";
import {postLogin, postVerify} from "../hooks/api";
import {MessageContext} from "../pages/App/App";

const {Search, OTP} = Input;
const {Text, Title} = Typography;

const EnterPhone = ({onButtonClick}) => {

    return (
        <Form
            name="basic"
            labelCol={{
                // span: 8,
            }}
            wrapperCol={{
                // span: 16,
            }}
            style={{
                minWidth: "80vw",
            }}
            layout={"vertical"}
            onFinish={(e) => {
                onButtonClick(e.email.toLowerCase(), e.phone);
            }}
            onFinishFailed={(e) => console.log(e)}
            autoComplete="off"
        >

            <Flex vertical gap={"middle"} align={"center"} style={{width: "80vw"}}>
                <Title level={3}>Верификация</Title>
                <Alert
                    style={{width: "70vw"}}
                    message="В целях предотвращения недобросовестного использования этого стенда,
                пожалуйста, введите почту и телефон."
                    type="info"
                    closable
                />
                <Form.Item
                    label="Почта"
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Введите почту',
                        },
                        {
                            pattern: "^.+@.+\\..+$",
                            message: 'Введите корректную почту'
                        },
                        {
                            pattern: "^[A-Za-z0-9_\\-@\\.]+$",
                            message: 'Разрешены только символы a-z, 0-9, _, -, @, .'
                        }
                    ]}
                >
                    <Input placeholder="john@example.org"
                           style={{width: "80vw", maxWidth: 400}}
                           allowClear/>
                </Form.Item>
                <Form.Item
                    label="Номер телефона"
                    name="phone"
                    rules={[
                        {
                            required: true,
                            message: 'Введите номер телефона',
                        },
                        {
                            pattern: "^\\d*$",
                            message: 'Введите корректный номер телефона'
                        }
                    ]}
                >
                    <Input addonBefore="+" placeholder="79998887766"
                           style={{width: "80vw", maxWidth: 400}}
                           allowClear/>
                </Form.Item>
                <Button autoFocus htmlType={"submit"} type={"primary"}
                        style={{width: "80vw", maxWidth: 400}}>Отправить
                    код</Button>
                <Divider dashed/>

            </Flex>
        </Form>
    );
}

const EnterOTP = ({onButtonClick}) => {
    const [otp, setOtp] = useState("");

    return (
        <Flex vertical gap={"middle"} align={"center"} style={{width: "70vw"}}>
            <Text strong style={{textAlign: 'center'}}>Введите код верификации, отправленный на вашу почту</Text>
            <Input.OTP onChange={(e) => setOtp(e)} formatter={(str) => str.toUpperCase()}/>
            <Button type={"primary"} onClick={() => onButtonClick(otp)}>Подтвердить</Button>
            <Divider dashed/>
        </Flex>
    );
}


const Auth = ({
                  setUserDidLogin
              }) => {
    const [currentStage, setCurrentStage] = useState("phone");
    const [verifyResponse, setVerifyResponse] = useState(null);
    const [loginResponse, setLoginResponse] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [showError, showSuccess] = useContext(MessageContext);

    useEffect(() => {
        if (verifyResponse && verifyResponse.status === "error") {
            showError(verifyResponse.message);
        } else if (verifyResponse && verifyResponse.status === "success") {
            showSuccess(verifyResponse.message);
            setCurrentStage("otp");
        }
    }, [verifyResponse, setVerifyResponse]);

    useEffect(() => {
        if (loginResponse && loginResponse.status === "error") {
            showError(loginResponse.message);
        } else if (loginResponse && loginResponse.status === "success") {
            setUserDidLogin(true);
            showSuccess(loginResponse.message);
        }
    }, [loginResponse, setLoginResponse]);

    const onPhoneButtonClick = (email, phone) => {
        postVerify(email, phone, setVerifyResponse);
        setUserEmail(email);
    }

    const onOTPButtonClick = (otp) => {
        postLogin(userEmail, otp, setLoginResponse);
    }

    if (currentStage === "phone") {
        return <EnterPhone onButtonClick={onPhoneButtonClick}/>;
    } else if (currentStage === "otp") {
        return <EnterOTP onButtonClick={onOTPButtonClick}/>;
    }

}


export default Auth;
