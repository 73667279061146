import {Rate, Skeleton} from "antd";


const Rating = (props) => {
    const {data, isLoaded, textType, maxValue = 10} = props;

    const value = (isLoaded && data && data[textType]) ? data[textType] : 1;
    return (isLoaded && data && data[textType]) ? <Rate disabled allowHalf value={(value * 5) / maxValue}/> : <Skeleton.Input active/>;

}

export default Rating;