import {Button, Card, Modal, Skeleton, Typography} from "antd";
import {useState} from "react";
import {EllipsisOutlined} from "@ant-design/icons";

const {Text} = Typography;

const min = (v1, v2) => (v1 < v2 ? v1 : v2);

const makeEllipsis = (text, count) => {
    if (text.length <= count) {
        return <Text>{text}</Text>;
    }
    return <Text>{text.slice(0, count)} <EllipsisOutlined style={{color: "#a8a8a8"}}/></Text>;
};

const TextModal = (props) => {
    const {isModalOpen, setIsModalOpen, title, text} = props;

    return (
        <Modal title={title}
               open={isModalOpen} onCancel={() => setIsModalOpen(false)}
               onOk={() => setIsModalOpen(false)}
               width={"95vw"}
               cancelButtonProps={{style: {display: 'none'}}}
               okButtonProps={{style: {display: 'none'}}}
               okText={"OK"}
        >
            <Text>
                {text}
            </Text>
        </Modal>
    );
}

const TextCard = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {isLoaded, data, textType, title} = props;


    return (
        <Card extra={isLoaded && <Button type={"link"} onClick={() => {
            setIsModalOpen(true);
        }}>View</Button>}
              title={title} bordered={false} style={{width: '100%', paddingInline: 30}}>
            {isLoaded && data && data[textType] ?
                makeEllipsis(data[textType], 1024)
                : <Skeleton active/>}
            <TextModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} title={title}
                       text={(isLoaded && data && data[textType]) ? data[textType] : null}/>
        </Card>
    );
}

export default TextCard;