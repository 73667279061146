import {Button, Card, Flex, Popconfirm, Typography} from "antd";
import {CopyOutlined} from "@ant-design/icons";

const {Text, Paragraph} = Typography;
const ApiDataCard = ({title, data, renewFunc = null, setRequestData = null}) => {

    return (
        <Card
            size="small"
            title={title}
            extra={
                <Flex gap={"small"}>
                    <Button type={"link"} onClick={() => {
                        navigator.clipboard.writeText(data)
                    }} icon={<CopyOutlined/>}></Button>
                    {renewFunc && <Popconfirm okText={"Да"} cancelText={"Нет"}
                                              title={"Заменить токен"}
                                              description={"Вы уверены, что " +
                                                  "хотите заменить токен? В таком случае, " +
                                                  "старый токен перестанет действовать."}
                                              onConfirm={() => {
                                                  renewFunc(setRequestData);
                                              }}><Button type={"link"}>Заменить</Button></Popconfirm>}
                </Flex>
            }
            style={{
                maxWidth: 500,
                width: '80vw'
            }}
        >
            <Flex vertical justify={"center"} align={"center"}>
                <Paragraph style={{textAlign: 'left', width: '100%'}}>
                    <pre>
                    <Text style={{color: "#509e4c"}}>{data}</Text>
                    </pre>
                </Paragraph>
            </Flex>
        </Card>
    );
}

export default ApiDataCard;