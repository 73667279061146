import {postCustomPromptCreate, postRenewToken, useCustomPrompts, useUser} from "../../hooks/api";
import {Alert, Button, Flex, Typography, Menu, Divider} from "antd";
import BalanceCard from "../../components/lkcards/balancecard";
import ApiDataCard from "../../components/lkcards/apidatacard";
import ApiEndpointCard from "../../components/lkcards/apiendpointcard";
import {useEffect, useState} from "react";
import Loading from "../../components/loading";
import {useNavigate} from "react-router-dom";
import CustomPrompt from "../../components/lkcards/customprompt";
import {ApiOutlined, PicLeftOutlined, PlusOutlined, RollbackOutlined, SettingOutlined} from "@ant-design/icons";
import {apiURL} from "../../config";
import BillingTable from "../../components/lkcomps/billingtable";

const {Title, Text} = Typography;


const LKMainPage = () => {
    const [requestData, setRequestData] = useState(null);
    const [user, isUserLoading, userError] = useUser([requestData]);
    const [customPrompts, customPromptsLoading, customPromptsError] = useCustomPrompts([requestData]);
    const [currentMenu, setCurrentMenu] = useState("main");


    // useEffect(() => {
    //
    // }, []);

    const navigate = useNavigate();
    if (!user) {
        return (
            <Loading/>
        );
    } else if (user && user.isAnonymous) {
        navigate("/");
    }

    const baseurl = apiURL;
    const token = "c3VyZXBsYW5ldGlldXNldW5pdmVyc2V0cmFpbnBoeXNpY2FsaG9zcGl0YWxjbGFzc2E=";
    const ai_analyze_audio_endpoint = {
        description: "Эта ручка получает ваш токен доступа и аудио файл записи звонка, запускает анализ и " +
            "возвращает ID задачи (" +
            "им можно воспользоваться для получения результата анализа)",
        method: "POST",
        curl: "curl -F \"file=@test.mp3\" -H \"Content-Type: multipart/form-data\" -H \"Authorization:" + user.token + "\" -X POST " + baseurl + "/analyze_audio",
        baseurl: baseurl,
        uri: "/analyze_audio",
        header: "Authorization " + user.token,
        inputExample: '{\n' +
            '    "file": string(binary),\n' +
            '}',
        outputExample: '{\n' +
            '  "task_id": "dd98578d-167c-4a29-9d1d-6f9e7e1798f4",\n' +
            '}'
    };
    const check_result_endpoint = {
        description: "Эта ручка получает ID задачи как параметр ссылки и возвращает результат анализа записи звонка",
        method: "GET",
        baseurl: baseurl,
        curl: "curl -X GET " + baseurl + "/check_result/\<task_id\>",
        uri: "/check_result/{task_id}",
        header: "Authorization " + user.token,
        inputExample: '{}',
        outputExample: '{\n' +
            '  "transcription": "",\n' +
            '  "summary": "",\n' +
            '  "agent_feedback": "",\n' +
            '  "concise_advice": "",\n' +
            '  "communication_skills": 6,\n' +
            '  "sales_technique": 5,\n' +
            '  "general": 6,\n' +
            '  "user_satisfaction_index": 6,\n' +
            '  "agent_performance": 5.5,\n' +
            '  "status": "SUCCESS"\n' +
            '}'
    };

    const items = [
        {
            label: "Главная",
            key: "main",
            icon: <PicLeftOutlined/>
        },
        {
            label: "API",
            key: "api",
            icon: <ApiOutlined/>
        },
        {
            label: "Настройки",
            key: "settings",
            icon: <SettingOutlined/>
        },
        {
            label: (
                <a href="/">
                    Демо
                </a>
            ),
            key: "demo",
            icon: <RollbackOutlined />
        },
    ];

    return (
        <Flex vertical gap={"large"} style={{minHeight: '100vh'}} justify={"flex-start"}>
            <Menu items={items}
                  mode={"horizontal"}
                  selectedKeys={[currentMenu]}
                  onClick={(e) => setCurrentMenu(e.key)}
                  style={{alignItems: "center"}}
            />
            {(currentMenu === "main") && <Flex vertical gap={"small"} style={{paddingBottom: 20}}>
                <Title level={3}>Информация</Title>
                <Flex wrap gap={"middle"} justify={"center"}>
                    <BalanceCard balance={{left: user.minutesLeft, total: user.minutesTotal}}/>
                    {!user.activatedLk && <Alert style={{width: 300}}
                                                 message={<Text>Обратитесь к <a
                                                     href={"https://mmca_support.t.me"}>менеджеру</a> для выбора и
                                                     активации тарифа.</Text>}/>}
                </Flex>
                <Divider/>
                <Title level={3}>Запросы</Title>
                <BillingTable/>
            </Flex>}
            {(currentMenu === "settings") && <Flex vertical gap={"small"} style={{paddingBottom: 20}}>
                <Title level={3}>Промпты</Title>
                <Flex wrap gap={"middle"} justify={"center"}>
                    <Button style={{maxWidth: 500, width: "80vw", minHeight: 400}} size={"large"} type={"dashed"} onClick={() => {
                        postCustomPromptCreate(setRequestData);
                    }}><PlusOutlined/> Добавить кастомный промпт</Button>
                    {customPrompts && customPrompts.map((prompt) => <CustomPrompt key={prompt.id}
                                                                                  setRequestData={setRequestData}
                                                                                  prompt={prompt}/>)}

                </Flex>
            </Flex>}
            {(currentMenu === "api") && <Flex vertical gap={"small"}>
                <Title level={3}>Данные для API</Title>
                <Flex wrap gap={"middle"} justify={"center"}>
                    <ApiDataCard title={"Base URL"} data={baseurl}/>
                    <ApiDataCard title={"Token"} renewFunc={postRenewToken} setRequestData={setRequestData}
                                 data={user.token}/>
                </Flex>
            </Flex>}
            {(currentMenu === "api") && <Flex vertical gap={"small"} style={{paddingBottom: '5vh'}}>
                <Title level={3}>API Эндпоинты</Title>
                <Flex wrap gap={"middle"} justify={"center"}>
                    <ApiEndpointCard title={"Analyze Audio"} endpoint={ai_analyze_audio_endpoint}/>
                    <ApiEndpointCard title={"Check Result"} endpoint={check_result_endpoint}/>
                </Flex>
            </Flex>}

        </Flex>
    );
}

export default LKMainPage;