import {Card, Flex, Progress, Statistic} from "antd";

const BalanceCard = ({balance}) => {

    const percentage = (balance && (balance.total !== 0)) ? ((balance.left / balance.total) * 100) : 0;

    return (
        <Card
            size="small"
            title="Баланс"
            style={{
                width: 300,
            }}
        >
            <Flex vertical align={"flex-start"}>
                <Statistic title="Осталось минут" value={balance.left} suffix={"/ " + balance.total}/>
                <Progress percent={percentage} showInfo={false}/>
            </Flex>
        </Card>
    );
}

export default BalanceCard;