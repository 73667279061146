import {Button, Card, Flex, Typography} from "antd";
import {CopyOutlined} from "@ant-design/icons";

const {Text, Paragraph} = Typography;
const ApiEndpointCard = ({title, endpoint}) => {
    // endpoint = {method: "POST", uri: "/analyze_audio", inputExample: "", outputExample: ""}

    return (
        <Card
            size="small"
            title={title}
            extra={<Button type={"link"} onClick={() => {
                navigator.clipboard.writeText(endpoint.curl)
            }}><CopyOutlined/> CURL</Button>}
            style={{
                maxWidth: 500,
                width: '80vw'
            }}
        >
            <Flex vertical justify={"center"} align={"flex-start"} gap={"middle"}>
                <Flex gap={"middle"}>
                    <Text strong style={{color: "#509e4c"}}>{endpoint.method}</Text>
                    <Text code style={{color: "#969e4c"}}>{endpoint.uri}</Text>
                </Flex>
                <Text strong>Описание</Text>
                <Text type={"secondary"}>{endpoint.description}</Text>
                <Paragraph style={{textAlign: 'left', width: '100%'}}>
                    <Text strong>Хэдеры</Text>
                    <pre>
                    <Text style={{color: "#969e4c"}}>{endpoint.header}</Text>
                    </pre>
                    <Text strong>Пример ввода</Text>
                    <pre>
                    <Text style={{color: "#969e4c"}}>{endpoint.inputExample}</Text>
                    </pre>
                    <Text strong>Пример вывода</Text>
                    <pre>
                    <Text style={{color: "#969e4c"}}>{endpoint.outputExample}</Text>
                    </pre>
                </Paragraph>
            </Flex>
        </Card>
    );
}

export default ApiEndpointCard;