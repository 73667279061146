import {Typography} from "antd";

const {Text} = Typography;

const ShowTries = ({tries}) => {
    const triesColors = {
        5: "#50e678",
        4: "#e6a550",
        3: "#e6a550",
        2: "#e6a550",
        1: "#e6a550",
        0: "#e65550",
        "∞": "#50e678"
    };

    const showTries = (tries === -1000) ? "∞" : tries;

    return (
        <Text strong>Осталось попыток: <Text style={{color: triesColors[showTries]}}>{showTries}</Text></Text>
    );
}

export default ShowTries;