import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './pages/App/App';
import {ConfigProvider, theme} from "antd";
import './index.css';
import {BrowserRouter} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ConfigProvider theme={{
                "token": {
                    "colorPrimary": "#9d16ff",
                    "colorInfo": "#9d16ff",
                    "fontFamily": "Montserrat"
                },
                // "algorithm": theme.darkAlgorithm
            }}>
                <App/>
            </ConfigProvider>
        </BrowserRouter>
    </React.StrictMode>
);
