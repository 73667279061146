import {Button, Upload, Card, Flex, Space, Statistic, Table, Typography, DatePicker, Select, Input, message} from "antd";
import {ArrowDownOutlined, ArrowUpOutlined, InboxOutlined} from "@ant-design/icons";
import {useContext, useEffect} from "react";
import {apiURL} from "../config";

const {Title, Text} = Typography;
const {Dragger} = Upload;

const ImportAudio = (props) => {
    const [messageApi, contextHolder] = message.useMessage();
    const {setUuid, disabled = false} = props;

    const upload_props = {
        style: {width: "80vw"},
        maxCount: 1,
        name: 'file',
        action: apiURL + "/demo/analyze_audio?uuid=stand_uuid",
        onChange(info) {
            const {status} = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                setUuid(info.file.response.task_id);
                messageApi.success(`Аудиофайл загружен. Ожидайте окончания анализа.`);
            } else if (status === 'error') {
                messageApi.error(`Ошибка при загрузке аудиофайла ${info.file.name}.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    return (
        <Dragger {...upload_props} disabled={disabled}>
            {contextHolder}
            <p className="ant-upload-drag-icon">
                <InboxOutlined/>
            </p>
            <p className="ant-upload-text">Нажмите или перенесите сюда аудиофайл для дальнейшей обработки</p>
            <p className="ant-upload-hint">
                .mp3, .ogg
            </p>
        </Dragger>
    );
}

export default ImportAudio;
