import {Button, Flex, Typography} from "antd";
import {apiURL} from "../../config";
import EditUser from "./edituser";

const {Title} = Typography;


const AdminPanel = () => {
    return (
        <Flex vertical gap={"middle"} align={"center"} style={{height: '100vh'}}>
            <Title level={3}>Админка</Title>
            <Button href={apiURL + "/admin/get_users"}>Выгрузить пользователей</Button>
            <EditUser/>
        </Flex>
    );
}

export default AdminPanel;
