import {Flex, Spin} from "antd";


const Loading = () => {
    return (
        <Flex vertical style={{width: "100vw", height: "100%"}}>
            <Spin/>
        </Flex>
    );
}

export default Loading;