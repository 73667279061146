import './App.css';
import {Button, ConfigProvider, Flex, Layout, theme, Typography, Progress, Divider, Skeleton, message} from "antd";
import {createContext, useEffect, useState} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Content} from "antd/es/layout/layout";
import DemoPage from "../demo/demopage";
import AdminPanel from "../../components/admin/adminpanel";
import LKMainPage from "../lk/lkmainpage";

const {Text, Title} = Typography;

export const MessageContext = createContext(null);

const contentStyle = {
    textAlign: 'center',
    minHeight: 120,
    lineHeight: '120px',
    // color: '#fff',
    backgroundColor: '#efefef00',
};

const App = () => {

    const [messageApi, contextHolder] = message.useMessage();

    const show_error = (text) => {
        messageApi.error(text);
    };

    const show_success = (text) => {
        messageApi.success(text);
    };

    return (
        <MessageContext.Provider value={[show_error, show_success]}>
            <Layout style={{
                overflowX: 'hidden',
                width: 'calc(100%)',
                maxWidth: 'calc(100%)',
                height: '100%',
            }}>
                <Content style={contentStyle}>
                    <Routes>
                        <Route exact path={"/"} element={<DemoPage/>}/>
                        <Route exact path={"/admin"} element={<AdminPanel/>}/>
                        <Route exact path={"/lk"} element={<LKMainPage/>}/>
                    </Routes>
                </Content>
                {contextHolder}
            </Layout>
        </MessageContext.Provider>
    );
}

export default App;
