import {Card, Flex, Rate, Skeleton, Typography} from "antd";
import TextCard from "./TextCard";
import Rating from "./rate";

const {Text} = Typography;


const ShowInfo = (props) => {
    const {data, isLoaded, uuid} = props;


    return (
        <Flex vertical align={"center"} gap={"small"} style={{width: "80vw", paddingBottom: "10vh", paddingTop: "5vh"}}>
            <Flex wrap gap={"small"} style={{width: "100%", maxWidth: "90vh"}}>
                <Card title="Rate" bordered={false} style={{width: '100%', paddingInline: 30}}>
                    <Flex vertical gap={"small"} align={"center"} justify={"center"}>
                        <Flex vertical align={"center"}>
                            <Text>General</Text>
                            <Rating data={data} isLoaded={isLoaded} textType={"general_assessment"}/>
                        </Flex>
                        <Flex vertical align={"center"}>
                            <Text>Agent performance</Text>
                            <Rating data={data} isLoaded={isLoaded} textType={"agent_performance"}/>
                        </Flex>
                        <Flex vertical align={"center"}>
                            <Text>Communication skills</Text>
                            <Rating data={data} isLoaded={isLoaded} textType={"communication_skills_assessment"}/>
                        </Flex>
                        <Flex vertical align={"center"}>
                            <Text>Sales technique</Text>
                            <Rating data={data} isLoaded={isLoaded} textType={"sales_technique_assessment"}/>
                        </Flex>
                        <Flex vertical align={"center"}>
                            <Text>User satisfaction</Text>
                            <Rating data={data} isLoaded={isLoaded} textType={"user_satisfaction_assessment"}/>
                        </Flex>
                    </Flex>
                </Card>
                <TextCard data={data} isLoaded={isLoaded} textType={"transcription"} title={"Transcription"}/>
                <TextCard data={data} isLoaded={isLoaded} textType={"summary"} title={"Summary"}/>
                <TextCard data={data} isLoaded={isLoaded} textType={"errors"} title={"Feedback"}/>
                <TextCard data={data} isLoaded={isLoaded} textType={"tips"} title={"Advice"}/>


            </Flex>
        </Flex>
    );
}

export default ShowInfo;