import {Button, Card, Flex, Select, Slider, Switch, Typography} from "antd";
import {CopyOutlined, DeleteOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import {postCustomPromptDelete, postCustomPromptToEdit} from "../../hooks/api";

const {Title, Paragraph, Text} = Typography;


const CustomPrompt = ({prompt, setRequestData}) => {
    const [responseData, setResponseData] = useState(null);
    const [deleteResponseData, setDeleteResponseData] = useState(null);
    const [title, setTitle] = useState(prompt.title);
    const [description, setDescription] = useState(prompt.description);
    const [gradeScale, setGradeScale] = useState(prompt.gradeScale);
    const [isEnabled, setIsEnabled] = useState(prompt["isEnabled"]);
    const [selectedType, setSelectedType] = useState(prompt.selectedType);

    useEffect(() => {
        postCustomPromptToEdit(prompt.id, {title, description, gradeScale, isEnabled: isEnabled.toString(), selectedType}, setResponseData);
    }, [title, description, gradeScale, isEnabled, selectedType]);

    return (
        <Card
            size="small"
            title={<Flex style={{height: 50, width: "100%"}} vertical align={"center"} justify={"center"}>
                <Text strong editable={{onChange: setTitle}}>{title}</Text>
            </Flex>}
            extra={<Flex gap={"small"} align={"center"}>
                <Switch onChange={(e) => setIsEnabled(e ? "true" : "false")} defaultValue={isEnabled}/>
                <Button type={"link"} onClick={() => {
                    postCustomPromptDelete(prompt.id, setRequestData);
                }} icon={<DeleteOutlined/>}></Button>
            </Flex>}
            style={{
                maxWidth: 500,
                width: '80vw'
            }}
        >
            <Flex vertical gap={"middle"} style={{width: "100%"}} justify={"space-between"}>
                <Flex vertical align={"flex-start"} gap={"small"}>
                    <Text strong>Тип</Text>
                    <Select defaultValue={selectedType} style={{width: "100%"}} options={[
                        {value: 'string', label: 'Строковый'},
                        {value: 'number', label: 'Оценочный'}
                    ]} onChange={(e) => setSelectedType(e)}/>
                </Flex>
                <Flex vertical align={"flex-start"} gap={"small"} style={{width: "100%"}}>
                    <Text strong>Промпт</Text>
                    <Paragraph style={{textAlign: "left", width: "100%"}}>
                        <pre>
                    <Text editable={{onChange: setDescription}} style={{textAlign: "left", width: '100%'}}>{description}</Text>
                        </pre>
                    </Paragraph>
                </Flex>
                <Flex vertical align={"flex-start"} gap={"small"}>
                    <Text strong>Шкала оценки</Text>
                    <Slider style={{width: "100%"}} min={5} max={20} defaultValue={gradeScale}
                            onChange={(e) => setGradeScale(e)} disabled={selectedType === "string"}/>
                </Flex>
            </Flex>
        </Card>
    );
}

export default CustomPrompt;