import {
    Button,
    ConfigProvider,
    Flex,
    Layout,
    theme,
    Typography,
    Progress,
    Divider,
    Skeleton,
    message,
    FloatButton, Tooltip,
    Tour
} from "antd";
import {createContext, useEffect, useState, useRef} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import ImportAudio from "../../components/uploader";
import ShowInfo from "../../components/showinfo";
import {CopyrightOutlined, LoginOutlined, ReloadOutlined} from "@ant-design/icons";
import {useReport, useUser} from "../../hooks/api";
import Auth from "../../components/auth";
import ShowTries from "../../components/showtries";
import AdminPanel from "../../components/admin/adminpanel";
import Loading from "../../components/loading";

const {Text, Title} = Typography;

const DemoPage = () => {
    const [uuid, setUuid] = useState(null);
    const [showUuid, setShowUuid] = useState(null);
    const [mustShowInfo, setMustShowInfo] = useState(false);
    const [doUpdate, setDoUpdate] = useState(0);
    const [progress, setProgress] = useState(0);
    const [data, isLoading, error] = useReport(uuid, [uuid, progress]);
    const [userDidLogin, setUserDidLogin] = useState(false);
    const [showData, setShowData] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [tourOpen, setTourOpen] = useState(false);
    const [user, isUserLoading, userError] = useUser([userDidLogin, setUserDidLogin, uuid, setUuid]);

    const lkRef = useRef(null);

    const navigate = useNavigate();

    const steps = [
        {
            title: 'Вход в личный кабинет',
            description: 'Нажмите на эту кнопку, чтобы перейти в личный кабинет',
            target: () => lkRef.current,
        },
    ]

    useEffect(() => {
        if (uuid) {
            setShowUuid(uuid);
            setMustShowInfo(true);
            const intervalId = setInterval(() => {
                setProgress((prevProgress) => {
                    setDoUpdate(prevProgress); // Trigger the report fetch
                    if (prevProgress >= 100) {
                        clearInterval(intervalId);
                    }
                    if (prevProgress >= 90) {
                        return 10;
                    }
                    return prevProgress + 3; // Increment progress

                });
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, [uuid]);

    useEffect(() => {
        if (data && data.status === "SUCCESS") {
            setMustShowInfo(true);
            setShowData(data.result);
            setIsLoaded(true);
            setUuid(null);
            setProgress(100);
        } else if (data && data.status === "FAILURE") {
            setMustShowInfo(true);
            setShowData(data.result);
            setIsLoaded(true);
            setUuid(null);
            setProgress(80);
        }

    }, [data]);

    if (user && user.isAnonymous) {
        return (
            <Flex vertical gap={"large"} align={"center"} justify={"flex-start"} style={{height: '100vh'}}>
                <Title level={1}>Call Analytics</Title>
                <Auth setUserDidLogin={setUserDidLogin}/>
                <ImportAudio setUuid={setUuid} disabled/>
            </Flex>

        );
    } else if (user) {
        return (
            <Flex vertical gap={"middle"} align={"center"} justify={"flex-start"} style={{height: '100vh'}}>
                <Title level={1}>Call Analytics</Title>
                <Tour open={tourOpen} onClose={() => setTourOpen(false)} steps={steps}/>

                {!user.activatedLk &&
                    <Button type={"link"} onClick={() => setTourOpen(true)}>Как попасть в личный кабинет?</Button>}
                <Button type={"link"}
                        href={"https://drive.usercontent.google.com/u/0/uc?id=1BoXX6s0thbG6S52lO32hYq6PlGGRbyxJ&export=download"}>
                    Скачать тестовый файл</Button>

                <ShowTries tries={user.triesLeft}/>
                <ImportAudio setUuid={setUuid} disabled={(!user.isAdmin) && (user.triesLeft <= 0)}/>
                {mustShowInfo &&
                    <Progress showInfo={false} percent={progress} style={{width: '80%', marginTop: '5vh'}}/>}
                {mustShowInfo && <Text style={{paddingTop: 10}} type={"secondary"}>Идентификатор запроса: <Text
                    code>{showUuid}</Text></Text>}
                <Text style={{paddingTop: 4}} type={"secondary"}>В случае возниковения проблем - обращайтесь в <a href={"https://mmca_support.t.me"}>поддержку</a></Text>
                {mustShowInfo && <ShowInfo isLoaded={isLoaded} data={showData}/>}

                <Tooltip title={"Войти в личный кабинет"}>
                    <FloatButton type={"primary"} ref={lkRef} icon={<LoginOutlined/>}
                                 onClick={() => navigate("/lk")}></FloatButton>
                </Tooltip>
            </Flex>
        );
    } else {
        return (
            <Flex vertical gap={"middle"} align={"center"} justify={"flex-start"} style={{height: '100vh'}}>
                <Title level={1}>Call Analytics</Title>
                <Loading/>
            </Flex>
        );
    }
}

export default DemoPage;
